import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Button from '@mui/material/Button';
import './maincontent.css';

const MainContent = ({ onFileUpload }) => {
  const handleNew = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.xls,.xlsx,.csv,.zip,.rar';
    fileInput.onchange = onFileUpload;
    fileInput.click();
  };

  return (
    <div className="main-content">
      <h1>Dig Deep for <span style={{ color: '#ff6347' }}>Data Insights</span></h1> 
      <div className="files">
        <Icon icon="material-symbols-light:tsv-outline" style={{ color: 'black', fontSize: '48px' }} />
        <Icon icon="material-symbols-light:csv-outline" style={{ color: 'black', fontSize: '48px' }} />
        <Icon icon="carbon:xls" style={{ color: 'black', fontSize: '48px' }} />
      </div>
      <div className="cta-section">
        <Button 
          variant="contained" 
          onClick={handleNew} 
          style={{ backgroundColor: 'black', color: 'white' }} 
          startIcon={<Icon icon="fluent:form-new-24-regular" style={{ color: '#e6f240' }} />}
        >
          Upload File
        </Button>
        {/* <Link to='/open' className="cta-button">
          <Icon icon="ri:import-fill" style={{ color: '#36cfc9' }} />
          <p>Import Data</p>
        </Link> */}
      </div>
    </div>
  );
};

export default MainContent;
