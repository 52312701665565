import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import logo from '../../../Assets/FullLogo.svg'; 
import { GoogleLogin } from '@react-oauth/google';
import './login.css';
import Divider from '@mui/material/Divider';
import { TextField, Button } from '@mui/material';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://localhost:8443/api/user/login/', formData);
      
      if (response.status === 200) {
        const { token, email } = response.data;
  
        localStorage.setItem('token', token);
        localStorage.setItem('email', email);
  
        console.log('Login successful:', response.data);
        navigate('/');
      }
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const googleResponse = await axios.post('https://localhost:8443/api/user/google-login/', { token: response.credential });
      
      if (googleResponse.status === 200) {
        const { token, email } = googleResponse.data;
  
        localStorage.setItem('token', token);
        localStorage.setItem('email', email);
  
        console.log('Google Login successful:', googleResponse.data);
        navigate('/projects');
      }
    } catch (error) {
      console.error('Google Login failed:', error);
      // handle error, show error message, etc.
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error('Google Login failed:', error);
    // handle error, show error message, etc.
  };

  return (
    <div className="login-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <h2>Log in to ridannt</h2>
      <form className="login-form">
        <TextField
          type="email"
          id="email"
          name="email"
          label="Email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          variant="filled"
          margin="normal"
          fullWidth
        />

        <TextField
          type="password"
          id="password"
          name="password"
          label="Password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          variant="filled"
          margin="normal"
          fullWidth
        />

        <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
          Login
        </Button>

        <Divider style={{ margin: '20px 0' }}>or</Divider>

        <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={handleGoogleLoginFailure}
        />

        <div className="forgot-password">
          <Link to="/forgot-password">
            Forgot Password?
          </Link>
        </div>

        <div className="dont-have-account">
          Don't have an account? <Link to="/signup">Sign up</Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
