import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import logo from '../../../Assets/FullLogo.svg'; 
import { GoogleLogin } from '@react-oauth/google';
import Divider from '@mui/material/Divider';
import { TextField } from '@mui/material';
import './signup.css';

const Signup = () => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleSignup = async () => {
    try {
      if (formData.password !== formData.confirmPassword) {
        setErrorMessage('Passwords do not match');
        return;
      }

      const response = await axios.post('https://localhost:8443/api/user/signup/', {
        email: formData.email,
        password: formData.password
      });

      if (response.status === 200) {
        setSuccessMessage(response.data.message);
        navigate('/login');
      } else if (response.status === 400) {
        setErrorMessage(response.data.error);
      } else {
        setErrorMessage(`Signup failed. Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      setErrorMessage(`Signup failed: ${error.message}`);
    }
  };

  const handleGoogleSignup = async (response) => {
    try {
      const googleToken = response.tokenId; // Access Google OAuth token
      // Make API call to your backend for Google OAuth signup
      const googleResponse = await axios.post('https://localhost:8443/api/user/google-signup/', { token: googleToken });

      if (googleResponse.status === 200) {
        const { token, email } = googleResponse.data;

        localStorage.setItem('token', token);
        localStorage.setItem('email', email);

        console.log('Google Signup successful:', googleResponse.data);
        navigate('/projects');
      } else {
        setErrorMessage('Google Signup failed');
      }
    } catch (error) {
      console.error('Google Signup failed:', error);
      setErrorMessage(`Google Signup failed: ${error.message}`);
    }
  };

  return (
    <div className="signup-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <h2>Create account</h2>
      {errorMessage && <p className="error-message" style={{ color: 'red' }}>{errorMessage}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      <form className="signup-form">
        <TextField
          type="email"
          id="email"
          name="email"
          label="Email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          variant="filled"
          margin="normal"
          fullWidth
        />

        <TextField
          type="password"
          id="password"
          name="password"
          label="Password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          variant="filled"
          margin="normal"
          fullWidth
        />

        <TextField
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          label="Confirm Password"
          value={formData.confirmPassword}
          onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
          variant="filled"
          margin="normal"
          fullWidth
        />

        <button type="button" className="signup-button" onClick={handleSignup}>
          Signup
        </button>

        <Divider style={{ margin: '20px 0' }}>or</Divider>

        <GoogleLogin
          clientId="YOUR_GOOGLE_CLIENT_ID"
          onSuccess={handleGoogleSignup}
          onFailure={(error) => console.error('Google login failed', error)}
          buttonText="Signup with Google"
        />

        <div className="signup-links">
          <p><span>Already have an account? <Link to="/login">Login</Link></span></p>
          <p><span>By signing up, you agree to our<Link to="/privacy-policy">Privacy Policy</Link></span></p>
        </div>
      </form>
    </div>
  );
};

export default Signup;
