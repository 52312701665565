import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  Login as LoginIcon,
  AppRegistration as AppRegistrationIcon,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import logo from '../../Assets/FullLogo.svg';
import './header.css';
import { AuthContext } from '../../Context/authContext';

const menuItems = (isLoggedIn) => [
  { label: 'Profile', icon: <AccountCircleOutlinedIcon fontSize="small" />, route: '/profile', auth: true },
  { label: 'Settings', icon: <SettingsIcon fontSize="small" />, route: '/settings', auth: true },
  { label: 'Logout', icon: <LogoutIcon fontSize="small" />, route: 'logout', auth: true },
  { label: 'Login', icon: <LoginIcon fontSize="small" />, route: '/login', auth: false },
  { label: 'Sign Up', icon: <AppRegistrationIcon fontSize="small" />, route: '/signup', auth: false },
];

function AccountMenu({ isLoggedIn, userEmail, handleLogout }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleMenuItemClick = (path) => {
    setAnchorEl(null);
    if (path === 'logout') handleLogout();
    else navigate(path);
  };

  return (
    <>
      <Tooltip title="Account">
        <IconButton
          onClick={(event) => setAnchorEl(event.currentTarget)}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
        >
          <AccountCircleOutlinedIcon sx={{ width: 32, height: 32 }} />
          {isLoggedIn && userEmail}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItems(isLoggedIn).map((item) => item.auth === isLoggedIn && (
          <MenuItem key={item.label} onClick={() => handleMenuItemClick(item.route)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

const DropdownList = ({ isLoggedIn, handleLogout }) => {
  const [openCollapse, setOpenCollapse] = React.useState(false);

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      <ListItem button onClick={() => setOpenCollapse(!openCollapse)}>
        <ListItemIcon>
          <AccountCircleOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Account" />
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menuItems(isLoggedIn).map((item) => item.auth === isLoggedIn && (
            <ListItem
              key={item.label}
              button
              component={Link}
              to={item.route === 'logout' ? '#' : item.route}
              onClick={item.route === 'logout' ? handleLogout : undefined}
            >
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  );
};

const MobileHeader = ({ isLoggedIn, userEmail, handleLogout }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className="mobile-header">
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box
          sx={{ width: '100vw' }}
          role="presentation"
        >
          <IconButton sx={{ position: 'absolute', right: 16, top: 16 }} onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
          <List sx={{ paddingLeft: 0, marginTop: '56px' }}>
            <ListItem button component={Link} to="/features">
              <ListItemText primary="Features" />
            </ListItem>
            <ListItem button component={Link} to="/docs">
              <ListItemText primary="Docs & Tutorials" />
            </ListItem>
            <ListItem button component={Link} to="/pricing">
              <ListItemText primary="Pricing" />
            </ListItem>
            <Divider />
            <ListItem>
              <DropdownList isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Link className="logo-container" to="/">
        <img src={logo} alt="Logo" className="logo" />
      </Link>
      <IconButton onClick={handleDrawerOpen} sx={{ marginLeft: 'auto', marginRight: '16px' }}>
        <MenuIcon />
      </IconButton>
    </div>
  );
};

const DesktopHeader = ({ isLoggedIn, userEmail, handleLogout }) => (
  <div className="header">
    <Link className="logo-container" to="/">
      <img src={logo} alt="Logo" className="logo" />
    </Link>
    <div className="links">
      <ul>
        <li><Link to="/features">Features</Link></li>
        <li><Link to="/docs">Docs & Tutorials</Link></li>
        <li><Link to="/pricing">Pricing</Link></li>
      </ul>
    </div>
    <div className="user-icon">
      <AccountMenu isLoggedIn={isLoggedIn} userEmail={userEmail} handleLogout={handleLogout} />
    </div>
  </div>
);

const Header = () => {
  const { isLoggedIn, email, logout } = useContext(AuthContext); // Consume AuthContext

  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); 
    navigate('/login');
  };

  return (
    <>
      <DesktopHeader isLoggedIn={isLoggedIn} userEmail={email} handleLogout={handleLogout} />
      <MobileHeader isLoggedIn={isLoggedIn} userEmail={email} handleLogout={handleLogout} />
    </>
  );
};

export default Header;
