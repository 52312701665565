// src/App.js

import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider, AuthContext } from './Context/authContext';
import Signup from './Components/Auth/Signup/signup';
import Login from './Components/Auth/Login/login';
import Landing from './Components/Home/home';
import ForgotPassword from './Components/Auth/Passwords/forgotpassword';
import ChangePassword from './Components/Auth/Passwords/changepassword';
import VerifyEmail from './Components/Auth/Verification/verifyEmail';
import Policy from './Components/Policy/policy';
import Documentation from './Components/HeaderPages/documentation';
import Features from './Components/HeaderPages/features';
import Pricing from './Components/HeaderPages/pricing';
import { GoogleOAuthProvider } from '@react-oauth/google';
import TableEditor from './Components/Tables/TableEditor';

const App = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify" element={<VerifyEmail />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/docs" element={<Documentation />} />
          <Route path="/features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/table-editor" element={<TableEditor />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ChangePassword />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
};

const WrappedApp = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default WrappedApp;
