import React, { useRef, useState, useEffect } from 'react';
import { Tree } from 'react-arborist';
import { TbFolderPlus } from "react-icons/tb";
import { AiOutlineFileAdd, AiFillFile } from 'react-icons/ai';
import { MdArrowDropDown, MdArrowRight, MdEdit } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import * as XLSX from 'xlsx';
import TableEditor from '../Tables/TableEditor';
import './explorer.css';

function FileExplorer({ treeData, setTreeData }) {
    const [term, setTerm] = useState("");
    const treeRef = useRef(null);
    const [selectedFileData, setSelectedFileData] = useState(null);

    useEffect(() => {
        console.log('Initial treeData:', treeData); // Debug log to check initial data
    }, [treeData]);

    const handleTreeChange = (newTreeData) => {
        console.log('Tree Data Changed:', newTreeData); // Debug log
        setTreeData(newTreeData);
    };

    const handleFileClick = async (file) => {
        console.log('File Clicked:', file); // Debug log to check file click
        if (file.fileData) {
            const arrayBuffer = await file.fileData.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            setSelectedFileData(jsonData);
        }
    };

    const handleFileUpload = async (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile) {
            if (
                uploadedFile.type === 'application/vnd.ms-excel' ||
                uploadedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                uploadedFile.type === 'text/csv'
            ) {
                const arrayBuffer = await uploadedFile.arrayBuffer();
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                setSelectedFileData(jsonData);
            } else {
                console.error('Unsupported file format');
            }
        }
    };

    const createFileFolder = (
        <>
            <TbFolderPlus
                size={24}
                color="black"
                onClick={() => treeRef.current.createInternal(treeRef.current.root.id)}
                title="New Folder..."
            />
            <AiOutlineFileAdd
                size={24}
                color="black"
                onClick={() => treeRef.current.createLeaf(treeRef.current.root.id)}
                title="New File..."
            />
        </>
    );

    
    const Node = ({ node, style, dragHandle, tree }) => {
        const [isHovered, setIsHovered] = useState(false);

        return (
            <div
                style={{ ...style, display: 'flex', alignItems: 'center' }}
                ref={dragHandle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div
                    onClick={() => node.isInternal && node.toggle()}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    {node.isLeaf ? <AiFillFile color="black" /> : (
                        <span onClick={() => node.toggle()}>
                            {node.isOpen ? <MdArrowDropDown /> : <MdArrowRight />}
                        </span>
                    )}
                    <span style={{ marginLeft: '8px' }}>
                        {node.isEditing ? (
                            <input
                                type="text"
                                defaultValue={node.data.name}
                                onFocus={(e) => e.currentTarget.select()}
                                onBlur={() => node.reset()}
                                onKeyDown={(e) => {
                                    if (e.key === 'Escape') node.reset();
                                    if (e.key === 'Enter') node.submit(e.currentTarget.value);
                                }}
                                autoFocus
                            />
                        ) : (
                            <span onClick={() => handleFileClick(node.data)}>{node.data.name}</span>
                        )}
                    </span>
                </div>
                {isHovered && (
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: '8px' }}>
                        <MdEdit
                            color="black"
                            onClick={() => node.edit()}
                            title="Rename..."
                        />
                        <RxCross2
                            color="black"
                            onClick={() => tree.delete(node.id)}
                            title="Delete"
                        />
                    </div>
                )}
            </div>
        );
    };

    const onCreate = ({ parentId, index, type }) => {
        console.log('Create action:', { parentId, index, type });
    };

    const onRename = ({ id, name }) => {
        console.log('Rename action:', { id, name });
        const updateName = (nodes) => {
            return nodes.map(node => {
                if (node.id === id) {
                    return { ...node, name };
                } else if (node.children) {
                    return { ...node, children: updateName(node.children) };
                } else {
                    return node;
                }
            });
        };
        setTreeData(updateName(treeData));
    };

    const onMove = ({ dragIds, parentId, index }) => {
        console.log('Move action:', { dragIds, parentId, index });
    };

    const onDelete = ({ ids }) => {
        console.log('Delete action:', { ids });
        const deleteNodes = (nodes) => {
            return nodes.filter(node => !ids.includes(node.id)).map(node => {
                if (node.children) {
                    return { ...node, children: deleteNodes(node.children) };
                } else {
                    return node;
                }
            });
        };
        setTreeData(deleteNodes(treeData));
    };

    return (
        <div className='explorer'>
            <div style={{ width: '200px', height: '100vh', overflow: 'auto' , padding:"10px"}}>
                <div className="folderFileActions">{createFileFolder}</div>
                <input
                    type="text"
                    placeholder="Search..."
                    className="search-input"
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                />
                <Tree
                    ref={treeRef}
                    data={treeData}
                    width={360} 
                    idAccessor="id"
                    onCreate={onCreate}
                    onRename={onRename}
                    onMove={onMove}
                    onDelete={onDelete}
                    childrenAccessor="children"
                    openByDefault={true}
                    rowHeight={20}
                    onChange={handleTreeChange}
                    searchTerm={term}
                    searchMatch={(node, term) =>
                        node.data.name.toLowerCase().includes(term.toLowerCase())
                    }
                >
                    {Node}
                </Tree>
            </div>
            <div style={{ flex: 1, padding: '10px' }}>
                {selectedFileData && (
                    <TableEditor data={selectedFileData} />
                )}
            </div>
        </div>
    );
}

export default FileExplorer;
