// DriveConnectModal.js

import React from 'react';
import Modal from 'react-modal';
import { Icon } from '@iconify/react';
import './modal.css';
const DriveConnectModal = ({ isOpen, closeModal, connectDrive }) => {
  const handleConnectDrive = (driveType) => {
    connectDrive(driveType);
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Connect to Drives"
      className="modal-overlay"
      overlayClassName="modal-overlay"
    >
      <div className="drive-connect-modal">
        <div className="modal-header">
          <h2>Connect to Drive</h2>
          <Icon icon="ion:close" className="close-icon" onClick={closeModal} />
        </div>
        <div className="drive-options">
          <div className="drive-option">
            <Icon icon="logos:google-drive" className="iconify" onClick={() => handleConnectDrive('Google Drive')} />
          </div>
          <div className="drive-option">
            <Icon icon="logos:dropbox" className="iconify" onClick={() => handleConnectDrive('Dropbox')} />
          </div>
          <div className="drive-option">
            <Icon icon="logos:microsoft-onedrive" className="iconify" onClick={() => handleConnectDrive('OneDrive')} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DriveConnectModal;
