import React from 'react';
import { Container, Grid, Card, CardContent, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import './features.css';
import Header from '../Header/Header';

const features = [
    { icon: "carbon:text-link-analysis", name: 'Qualitative Analysis', description: 'Perform in-depth qualitative analysis of your data to gain insights and make informed decisions.' },
    { icon: "oui:app-visualize", name: 'Visualization', description: 'Visualize your data with stunning charts and graphs to better understand trends and patterns.' },
    { icon: "openmoji:collaboration", name: 'Collaborators', description: 'Collaborate with your team members or clients by inviting them to work together on projects.' },
    { icon: "iwwa:export", name: 'Export Reports', description: 'Export your analysis reports in various formats, such as PDF and Word, for easy sharing and presentation.' },
    { icon: "arcticons:ox-drive", name: 'Drive Integration', description: 'Integrate with Google Drive to seamlessly access and save your data and reports.' }
];

const Features = () => {
    return (
        <div className='feature-page'>
            <Header />
            <div className='main'>
                <Container maxWidth="md" style={{ marginBottom: '2rem', paddingTop: 40 }}>
                    <Typography variant="h3" align="center" gutterBottom className='features-title'>
                        Features
                    </Typography>
                    <Grid container spacing={4} justifyContent="center">
                        {features.map((feature, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card>
                                    <CardContent style={{ textAlign: 'center' }}>
                                        <Icon icon={feature.icon} style={{ fontSize: '48px', color: '#3f51b5' }} />
                                        <Typography variant="h6" component="div" gutterBottom>
                                            {feature.name}
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary">
                                            {feature.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </div>
        </div>
    );
}

export default Features;
