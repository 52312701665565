import React from 'react';
import PropTypes from 'prop-types';

const Result = ({ visualizeResponse, reportText }) => {
  return (
    <div>
      {visualizeResponse ? (
        <div>
          <h2>Visualize Response</h2>
          <img src={visualizeResponse} alt="Visualization" />
        </div>
      ) : (
        <p>No visualization available</p>
      )}
      {reportText ? (
        <div>
          <h2>Report Text</h2>
          <p>{reportText}</p>
        </div>
      ) : (
        <p>No report text available</p>
      )}
    </div>
  );
};

Result.propTypes = {
  visualizeResponse: PropTypes.string,
  reportText: PropTypes.string,
};

export default Result;
