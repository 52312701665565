import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Sidebar from '../Navbar/navbar';
import MainContent from './MainContent';
import FeatureSection from './FeatureSection';
import TableEditor from '../Tables/TableEditor';
import FileExplorer from '../Explorer/explorer';
import TableToolbar from '../Tables/TableToolbar';
import {
  useAddRow,
  useDeleteRow,
  useMenu,
  useAnalysisMethodSelect,
  useVisualizeData,
  useCleanData,
  useGenerateReport,
} from '../Tables/toolbarActions';
import './landing.css';
import JSZip from 'jszip';
import * as XLSX from 'xlsx';
import axios from 'axios';

const Landing = () => {
  const [showTableEditor, setShowTableEditor] = useState(false);
  const [showExplorer, setShowExplorer] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [selectedRowsState, setSelectedRowsState] = useState([]);

  const { anchorEl, openMenu, closeMenu } = useMenu();
  const { anchorEl: anchorElForAnalysis, selectAnalysisMethod } = useAnalysisMethodSelect();
  const { visualizeResponse, showReport, visualizeData } = useVisualizeData();
  const cleanData = useCleanData();
  const { reportText, generateReport } = useGenerateReport();

  const { rows, addRow } = useAddRow();
  const { selectedRows: selectedRows_, deleteRow, setRows } = useDeleteRow();

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const validFileTypes = [
      'application/x-rar-compressed',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
    ];

    if (!validFileTypes.includes(file.type) && !file.name.match(/\.(zip|rar)$/)) {
      alert('Only Excel or table files can be uploaded.');
      return;
    }

    const newTreeData = [...treeData];

    if (file.name.match(/\.(zip|rar)$/)) {
      const zip = new JSZip();
      const zipContent = await zip.loadAsync(file);

      await Promise.all(
        Object.keys(zipContent.files).map(async (relativePath) => {
          const zipEntry = zipContent.files[relativePath];
          if (!zipEntry.dir) {
            const fileData = await zipEntry.async('blob');
            const newFile = {
              id: `file${Date.now()}`,
              name: relativePath,
              fileData,
            };

            let folderName = relativePath.split('/')[0];
            if (!folderName) {
              folderName = 'Untitled';
            }

            let folder = newTreeData.find((folder) => folder.name === folderName);
            if (!folder) {
              folder = {
                id: `folder${Date.now()}`,
                name: folderName,
                children: [newFile],
              };
              newTreeData.push(folder);
            } else {
              folder.children.push(newFile);
            }
          }
        })
      );
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        setFileData(parsedData);
        setShowTableEditor(true);
      };
      reader.readAsBinaryString(file);

      const folderName = 'Untitled';
      const newFile = {
        id: `file${Date.now()}`,
        name: file.name,
      };

      let untitledFolder = newTreeData.find((folder) => folder.name === folderName);
      if (!untitledFolder) {
        untitledFolder = {
          id: `folder${Date.now()}`,
          name: folderName,
          children: [newFile],
        };
        newTreeData.push(untitledFolder);
      } else {
        untitledFolder.children.push(newFile);
      }
    }
    setShowExplorer(true);
    setTreeData(newTreeData);
  };

  return (
    <div className="landing">
      {showExplorer ? (
        <div className="alt-main">
          <div className="toolbar">
            <TableToolbar
              selectedRows={selectedRowsState}
              handleAddRow={addRow}
              handleDeleteRow={deleteRow}
              handleMenuOpen={openMenu}
              handleMenuClose={closeMenu}
              handleAnalysisMethodSelect={selectAnalysisMethod}
              handleVisualizeData={visualizeData}
              handleCleanData={cleanData}
              generateReport={generateReport}
              anchorEl={anchorEl}
            />
          </div>
          <div className="sidebar">
            <Sidebar onFileUpload={handleFileUpload} />
            <FileExplorer treeData={treeData} setTreeData={setTreeData} />
          </div>
          <div className="main">
            <TableEditor
              data={fileData}
              handleAddRow={addRow}
              handleDeleteRow={deleteRow}
              handleMenuOpen={openMenu}
              handleMenuClose={closeMenu}
              handleAnalysisMethodSelect={selectAnalysisMethod}
              handleVisualizeData={visualizeData}
              handleCleanData={cleanData}
              generateReport={generateReport}
              selectedRows={selectedRowsState}
              setSelectedRows={setSelectedRowsState}
              anchorEl={anchorEl}
              visualizeResponse={visualizeResponse}
              reportText={reportText}
              showReport={showReport}
            />
          </div>
        </div>
      ) : (
        <div className="first-main">
          <div className="header">
            <Header />
          </div>
          <div className="main-default">
            <MainContent onFileUpload={handleFileUpload} />
            <FeatureSection />
          </div>
          <div className="footer">
            <Footer />
          </div>
        </div>
      )}
    </div>
  );
};

export default Landing;
