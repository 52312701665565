import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerifyEmail = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email64 = searchParams.get('email64');
  const token = searchParams.get('token');
  
  const [verificationStatus, setVerificationStatus] = useState('');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        if (!email64 || !token) {
          setVerificationStatus('Invalid verification link');
          return;
        }
        
        const response = await axios.post('https://127.0.0.1:8000/api/user/verify-email/', { email64, token });
        if (response.status === 200) {
          setVerificationStatus(response.data.message);

        } else if (response.status === 400) {
          setVerificationStatus(response.data.error);
        } else {
          setVerificationStatus('Unexpected error occurred');
        }
      } catch (error) {
        setVerificationStatus('Error verifying email. Please try again later.');
        console.error('Failed to verify email:', error);
      }
    };

    verifyEmail();
  }, [email64, token]);

  return (
    <div>
      <h1>Email Verification</h1>
      <p>{verificationStatus}</p>
    </div>
  );
};

export default VerifyEmail;
