import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button } from '@mui/material'; 
import './forgot.css'; 

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState('');
  const [error, setError] = useState('');

  const handleResetPassword = async () => {
    try {
      const response = await axios.post('https://localhost:8443/api/user/forgot-password', { email });
      if (response.status === 200) {
        setForgotPasswordStatus(response.data.message);
      }
    } catch (error) {
      setError(error.response.data.error || 'Failed to reset password');
    }
  };

  return (
    <div className="forgot-password-container">
      <h2>Forgot Password</h2>
      {forgotPasswordStatus && <p style={{ color: 'green' }}>{forgotPasswordStatus}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <p>Enter your email to reset your password.</p>
      <form className="forgot-password-form">
        <TextField
          id="filled-hidden-label-small"
          label="Email"
          variant="filled"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <Button variant="contained" onClick={handleResetPassword}>
          Reset Password
        </Button>
      </form>

      <div className="login-link">
        <Link to="/login">Back to Login</Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
