import React, { useState } from 'react';
import { useMediaQuery, BottomNavigation, BottomNavigationAction, Grid, Button, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel } from '@mui/material';
import { Icon } from '@iconify/react';
import './toolbar.css';
import PropTypes from 'prop-types';

const QuantitativeMethods = [
  'correlation_matrix', 
  'covariance_matrix', 
  'linear_regression', 
  'descriptive_statistics',
  'summary_statistics_categorical',
  't_test',
  'chi_square_test',
  'normality_test',
  'fit_distribution',
  'detect_outliers_zscore',
  'time_series_analysis',
  'calculate_growth_rate',
  'trend_analysis',
  'cramers_v',
  'group_t_test',
  'anova',
  'kruskal_wallis',
  'wilcoxon_signed_rank_test'
];

const visualizationTypes = [
  'Bar Chart',
  'Line Chart',
  'Pie Chart',
  'Scatter Plot',
  'Histogram'
];

const methodsRequiringSelection = [
  'correlation_matrix',
  'covariance_matrix',
  'linear_regression',
  't_test',
  'group_t_test',
  'anova',
  'kruskal_wallis',
  'wilcoxon_signed_rank_test'
];

const MobileToolbar = ({ handleAddRow, handleDeleteRow, handleAnalysisMethodSelect, handleVisualizeData, handleCleanData, generateReport }) => {
  return (
    <BottomNavigation className="mobile-toolbar">
      <BottomNavigationAction label="Add Row" icon={<Icon icon="ci:add-row" />} onClick={handleAddRow} />
      <BottomNavigationAction label="Quantitative Analysis" icon={<Icon icon="mdi:chart-scatter-plot" size={24}/>} onClick={handleAnalysisMethodSelect} />
      <BottomNavigationAction label="Visualize Data" icon={<Icon icon="mdi:chart-bar" size={24} />} onClick={handleVisualizeData} />
      <BottomNavigationAction label="Clean Data" icon={<Icon icon="mdi:database-remove" size={24}/>} onClick={handleCleanData} />
      <BottomNavigationAction label="Generate Report" icon={<Icon icon="mdi:text-box-outline" size={24}/>} onClick={generateReport} />
    </BottomNavigation>
  );
};

const Toolbar = ({ selectedRows, handleAddRow, handleDeleteRow, handleAnalysisMethodSelect, handleVisualizeData, handleCleanData, generateReport }) => {
  const [analysisMenuAnchorEl, setAnalysisMenuAnchorEl] = useState(null);
  const [visualizationMenuAnchorEl, setVisualizationMenuAnchorEl] = useState(null);
  const [selectionDialogOpen, setSelectionDialogOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [allColumns, setAllColumns] = useState(['Column1', 'Column2', 'Column3']);
  const [currentMethod, setCurrentMethod] = useState('');
  const [currentVisualization, setCurrentVisualization] = useState('');

  const handleOpenSelectionDialog = (method) => {
    setCurrentMethod(method);
    setSelectionDialogOpen(true);
  };

  const handleCloseSelectionDialog = () => {
    setSelectionDialogOpen(false);
    setCurrentMethod('');
    setCurrentVisualization('');
  };

  const handleColumnSelect = (column) => {
    setSelectedColumns((prevSelectedColumns) =>
      prevSelectedColumns.includes(column)
        ? prevSelectedColumns.filter((col) => col !== column)
        : [...prevSelectedColumns, column]
    );
  };

  const handleConfirmSelection = () => {
    if (currentMethod) {
      handleAnalysisMethodSelect(currentMethod, selectedColumns);
    } else if (currentVisualization) {
      handleVisualizeData(currentVisualization, selectedColumns.length ? selectedColumns : allColumns);
    }
    handleCloseSelectionDialog();
  };

  const handleAnalysisClick = (method) => {
    if (methodsRequiringSelection.includes(method)) {
      handleOpenSelectionDialog(method);
    } else {
      handleAnalysisMethodSelect(method);
    }
  };

  const handleVisualizationClick = (type) => {
    setCurrentVisualization(type);
    setSelectionDialogOpen(true);
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center" className="toolbar">
        <Grid item>
          <Button variant="contained" color="primary" startIcon={<Icon icon="ci:add-row" />} onClick={handleAddRow}>
            Add Row
          </Button>
        </Grid>
        {/* <Grid item>
          <Button
            variant="contained"
            color="error"
            startIcon={<Icon icon="material-symbols-light:delete-outline" />}
            onClick={handleDeleteRow}
            disabled={selectedRows.length === 0}
          >
            Delete Selected Rows
          </Button>
        </Grid> */}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => setAnalysisMenuAnchorEl(event.currentTarget)}
            startIcon={<Icon icon="mdi:chart-scatter-plot" />}
            aria-haspopup="true"
            aria-controls="analysis-menu"
          >
            Quantitative Analysis
          </Button>
          <Menu
            anchorEl={analysisMenuAnchorEl}
            open={Boolean(analysisMenuAnchorEl)}
            onClose={() => setAnalysisMenuAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            id="analysis-menu"
            keepMounted
          >
            {QuantitativeMethods.map((method) => (
              <MenuItem key={method} onClick={() => handleAnalysisClick(method)}>
                {method.replace(/_/g, ' ')}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => setVisualizationMenuAnchorEl(event.currentTarget)}
            startIcon={<Icon icon="mdi:chart-bar" />}
            aria-haspopup="true"
            aria-controls="visualization-menu"
          >
            Visualize Data
          </Button>
          <Menu
            anchorEl={visualizationMenuAnchorEl}
            open={Boolean(visualizationMenuAnchorEl)}
            onClose={() => setVisualizationMenuAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            id="visualization-menu"
            keepMounted
          >
            {visualizationTypes.map((type) => (
              <MenuItem key={type} onClick={() => handleVisualizationClick(type)}>
                {type}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon icon="mdi:database-remove" />}
            onClick={handleCleanData}
          >
            Clean Data
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon icon="mdi:text-box-outline" />}
            onClick={generateReport}
          >
            Generate Report
          </Button>
        </Grid>
      </Grid>

      <Dialog open={selectionDialogOpen} onClose={handleCloseSelectionDialog}>
        <DialogTitle>Select Columns for {currentMethod ? currentMethod.replace(/_/g, ' ') : currentVisualization}</DialogTitle>
        <DialogContent>
          {allColumns.map((column) => (
            <FormControlLabel
              key={column}
              control={
                <Checkbox
                  checked={selectedColumns.includes(column)}
                  onChange={() => handleColumnSelect(column)}
                />
              }
              label={column}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSelectionDialog} color="error">
            Cancel
          </Button>
          <Button onClick={handleConfirmSelection} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Toolbar.propTypes = {
  selectedRows: PropTypes.array.isRequired,
  handleAddRow: PropTypes.func.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
  handleAnalysisMethodSelect: PropTypes.func.isRequired,
  handleVisualizeData: PropTypes.func.isRequired,
  handleCleanData: PropTypes.func.isRequired,
  generateReport: PropTypes.func.isRequired,
};

const TableToolbar = (props) => {
  const isMobileDevice = useMediaQuery('(max-width: 600px)');

  return (
    <>
      {isMobileDevice ? (
        <MobileToolbar {...props} />
      ) : (
        <Toolbar {...props} />
      )}
    </>
  );
};

export default TableToolbar;
