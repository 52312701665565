import React, { createContext, useReducer, useEffect } from 'react';

const initialState = {
  isLoggedIn: false,
  token: null,
  email: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'USER_LOGGED_IN':
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload.token,
        email: action.payload.email,
      };
    case 'USER_LOGGED_OUT':
      return {
        ...state,
        isLoggedIn: false,
        token: null,
        email: null,
      };
    default:
      return state;
  }
};

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    if (token) {
      dispatch({ type: 'USER_LOGGED_IN', payload: { token, email } });
    }
  }, []);

  const login = (token, email) => {
    localStorage.setItem('token', token);
    localStorage.setItem('email', email);
    dispatch({ type: 'USER_LOGGED_IN', payload: { token, email } });
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    dispatch({ type: 'USER_LOGGED_OUT' });
  };

  return (
    <AuthContext.Provider value={{ ...state, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
