import React from 'react';
import { Icon } from '@iconify/react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <a href="https://www.reddit.com/r/yourcommunity" target="_blank" rel="noopener noreferrer">
      <Icon icon="logos:reddit-icon" /> Reddit Community
      </a>
      <a href="https://ridaant.slack.com/join/shared_invite/zt-2ksuthum8-8k48Id~QpmaQM_TURnYUhQ" target="_blank" rel="noopener noreferrer">
        <Icon icon="devicon:slack" /> Slack Community
      </a>
      <a href="https://chat.whatsapp.com/Hi8nGq78GAv9X9MVgz76iV" target="_blank" rel="noopener noreferrer">
        <Icon icon="logos:whatsapp-icon" /> WhatsApp Community
      </a>
      <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
        <Icon icon="dashicons:privacy"  style={{color: "black"}} /> Privacy Policy
      </a>
      <span>&copy; Ridannt {currentYear}</span>
    </footer>
  );
};

export default Footer;
