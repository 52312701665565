import { useState } from 'react';
import axios from 'axios';

export const useAddRow = () => {
  const [rows, setRows] = useState([]);
  
  const addRow = (columns) => {
    const newRow = { id: rows.length, ...columns.reduce((acc, col) => ({ ...acc, [col.field]: '' }), {}) };
    setRows([...rows, newRow]);
  };
  
  return { rows, addRow };
};

export const useDeleteRow = () => {
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  
  const deleteRow = () => {
    const newRows = rows.filter((row) => !selectedRows.includes(row.id));
    setRows(newRows);
    setSelectedRows([]);
  };

  return { rows, selectedRows, deleteRow, setRows, setSelectedRows };
};

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return { anchorEl, openMenu, closeMenu };
};

export const useAnalysisMethodSelect = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const selectAnalysisMethod = (method) => {
    console.log(`Selected analysis method: ${method}`);
    setAnchorEl(null);
  };

  return { anchorEl, selectAnalysisMethod };
};

export const useVisualizeData = () => {
  const [visualizeResponse, setVisualizeResponse] = useState(null);
  const [showReport, setShowReport] = useState(false);

  const visualizeData = async (gridData) => {
    try {
      const response = await axios.post('/api/visualize', { data: gridData });
      setVisualizeResponse(response.data);
      setShowReport(true);
    } catch (error) {
      console.error('Error visualizing data:', error);
      // Handle error
    }
  };

  return { visualizeResponse, showReport, visualizeData };
};

export const useCleanData = () => {
  const cleanData = () => {
    console.log('Clean data');
  };

  return cleanData;
};

export const useGenerateReport = () => {
  const [reportText, setReportText] = useState("");
  const [showReport, setShowReport] = useState(false);

  const generateReport = () => {
    const report = "This is a sample report text.";
    setReportText(report);
    setShowReport(true);
    console.log(report);
  };

  return { reportText, showReport, generateReport };
};
