import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleChangePassword = async () => {
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const uidb64 = queryParams.get('uid64');
    const token = queryParams.get('token');

    try {
      const response = await axios.post('http://127.0.0.1/api/user/reset-password/', { uidb64, token, password });
      if (response.status === 200){
        setMessage(response.data.message);
        navigate('/login');
      }
      else if (response.status === 400){
        setError(response.data.error);
      }
    } catch (error) {
      setError(error.response.data.error || 'Failed to change password');
    }
  };

  return (
    <div>
      <h2>Change Password</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {message && <p style={{ color: 'green' }}>{message}</p>}
      <form>
        <label htmlFor="password">New Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        <button type="button" onClick={handleChangePassword}>
          Change Password
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
