import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, Divider, Tooltip, Button, Menu, MenuItem } from '@mui/material';
import { Icon } from '@iconify/react';
import logo from '../../Assets/Logo.svg';
import DriveConnectModal from './DriveModal';
import FileExplorer from '../Explorer/explorer';
import JSZip from 'jszip';
import * as XLSX from 'xlsx';
import './navbar.css';

const Brand = () => (
  <div className="brand">
    <img src={logo} alt="Logo" className="logo" />
  </div>
);

const Create = ({ handleNew }) => (
  <div className="new" onClick={handleNew}>
    <Icon icon="ph:upload-light" style={{ color: "black" }} />
  </div>
);

const MenuContent = ({ openDriveModal }) => (
  <>
    <Tooltip title="home">
      <Link to="/" className="menu-item">
        <Icon icon="streamline:dashboard-3" />
      </Link>
    </Tooltip>
    <Tooltip title="Collection">
      <Link to="/questionnaires" className="menu-item">
        <Icon icon="fluent-mdl2:questionnaire" />
      </Link>
    </Tooltip>
    <Tooltip title="Data & Analytics">
      <Link to="/data" className="menu-item">
        <Icon icon="material-symbols-light:analytics-outline-rounded" />
      </Link>
    </Tooltip>
    <Tooltip title="Shared With You">
      <Link to="/team" className="menu-item">
        <Icon icon="mingcute:group-3-line" />
      </Link>
    </Tooltip>
    <Divider sx={{ borderWidth: '2px' }} />
    <Tooltip title="Sync with Drive">
      <div className="menu-item" onClick={openDriveModal}>
        <Icon icon="mdi:cloud-sync-outline" />
      </div>
    </Tooltip>
  </>
);

const Mobilenav = ({ handleNew, openDriveModal }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='mobile'>
      <div className='header'>
        <Button
          aria-controls={open ? 'mobile-menu' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Icon icon="line-md:menu" />
        </Button>
        <Menu
          id="mobile-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Link to="/" className="menu-item">
              <Icon icon="streamline:dashboard-3" />
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/questionnaires" className="menu-item">
              <Icon icon="fluent-mdl2:questionnaire" />
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/data" className="menu-item">
              <Icon icon="material-symbols-light:analytics-outline-rounded" />
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/team" className="menu-item">
              <Icon icon="mingcute:group-3-line" />
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className="menu-item" onClick={openDriveModal}>
              <Icon icon="mdi:cloud-sync-outline" />
            </div>
          </MenuItem>
        </Menu>
      </div>
      <Create handleNew={handleNew} />
    </div>
  );
};

const Desktopnav = ({ handleNew, openDriveModal }) => (
  <div className='main'>
    <div className='sidebar'>
      <Create handleNew={handleNew} />
      <div className="desktop-menu">
        <MenuContent openDriveModal={openDriveModal} />
      </div>
    </div>
  </div>
);

const Navbar = ({ onFileUpload }) => {
  const [isDriveModalOpen, setDriveModalOpen] = useState(false);
  const [connectedDrives, setConnectedDrives] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const toggleDrawer = (isOpen) => () => {
    setOpen(isOpen);
  };

  const handleNew = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.xls,.xlsx,.csv,.zip,.rar';
    fileInput.onchange = onFileUpload;
    fileInput.click();
  };

  const openDriveModal = () => {
    setDriveModalOpen(true);
  };

  const closeDriveModal = () => {
    setDriveModalOpen(false);
  };

  const connectDrive = (driveType) => {
    if (!connectedDrives.includes(driveType)) {
      setConnectedDrives([...connectedDrives, driveType]);
    }
  };

  return (
    <>
      <Desktopnav handleNew={handleNew} openDriveModal={openDriveModal} />
      <Mobilenav handleNew={handleNew} openDriveModal={openDriveModal} />
      <DriveConnectModal isOpen={isDriveModalOpen} closeModal={closeDriveModal} connectDrive={connectDrive} />
    </>
  );
};

export default Navbar;
