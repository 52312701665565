import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../Header/Header';


const Documentation = () => {
  const [documentationContent, setDocumentationContent] = useState('');

  useEffect(() => {
    const fetchDocumentationContent = async () => {
      try {
        const response = await axios.get('/api/documentation'); 
        setDocumentationContent(response.data); 
      } catch (error) {
        console.error('Error fetching documentation content:', error);
      }
    };

    fetchDocumentationContent();

    // Cleanup function
    return () => {
      // Cleanup if necessary
    };
  }, []);

  return (
    <div>
      <Header />
      <main>
        <h1>Documentation</h1>
        <div dangerouslySetInnerHTML={{ __html: documentationContent }}></div>
      </main>
      
    </div>
  );
};

export default Documentation;
