import React from 'react';
import { Icon } from '@iconify/react';
import './featuresection.css'

const FeatureSection = () => {
  return (
    <div className="feature-section">
      <div className="features">
        <div className="feature">
          <h5><Icon icon="fluent:document-text-extract-20-filled" style={{ color: '#17f613' }} />Qualitative & Quantitative</h5>
          <p>Extract meaningful insights</p>
        </div>
        <div className="feature">
          <h5><Icon icon="carbon:collaborate" style={{ color: '#f6136e' }} />Collaborate</h5>
          <p>Real-Time Collaborative Research</p>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
