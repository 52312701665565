import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, CardActions, Typography, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { green } from '@mui/material/colors';
import Header from '../Header/Header';
import axios from 'axios';

const Pricing = () => {
    const [currency, setCurrency] = useState('USD');
    const [freePlanPrice, setFreePlanPrice] = useState(5);
    const [premiumPlanPrice, setPremiumPlanPrice] = useState(16.99);

    useEffect(() => {
        const fetchLocation = async () => {
            try {
                const response = await axios.get('https://ipapi.co/json/');
                if (response.data.country_name === 'Cameroon') {
                    setCurrency('XAF');
                    setFreePlanPrice(3000); // Example conversion
                    setPremiumPlanPrice(10000); // Example conversion
                }
            } catch (error) {
                console.error('Error fetching location:', error);
            }
        };
        fetchLocation();
    }, []);

    return (
        <div>
            <div className="pricing-header">
                <Header />
            </div>
            <div className='pricing-container'>
                <Container maxWidth="md" style={{ marginTop: '2rem', marginBottom: '2rem', textAlign: 'center' }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Choose Your Plan
                    </Typography>
                    <Grid container spacing={4} justifyContent="center">
                        {/* Free Plan */}
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Free
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                                            <li><CheckIcon style={{ color: green[500] }} /> Qualitative analysis</li>
                                            <li><CheckIcon style={{ color: green[500] }} /> Quantitative analysis</li>
                                            <li><CheckIcon style={{ color: green[500] }} /> Visualization</li>
                                            <li><CheckIcon style={{ color: green[500] }} /> Up to 10 collaborators</li>
                                        </ul>
                                    </Typography>
                                    <Typography variant="h6" component="div" gutterBottom>
                                        Pay as You Go - {currency} {freePlanPrice}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                                            <li><CheckIcon style={{ color: green[500] }} /> Export up to 3 reports (PDF, Word)</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="large" variant="contained" fullWidth style={{ backgroundColor: 'black', color: 'white' }}>
                                        Default
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        {/* Premium Plan */}
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Premium - {currency} {premiumPlanPrice}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                                            <li><CheckIcon style={{ color: green[500] }} /> All Free Plan features</li>
                                            <li><CheckIcon style={{ color: green[500] }} /> Unlimited collaborators</li>
                                            <li><CheckIcon style={{ color: green[500] }} /> Unlimited Exports</li>
                                            <li><CheckIcon style={{ color: green[500] }} /> Export in HTML, LaTeX, JS</li>
                                            <li><CheckIcon style={{ color: green[500] }} /> Drive integration</li>
                                        </ul>
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="large" variant="contained" fullWidth style={{ backgroundColor: 'black', color: 'white' }}>
                                        Upgrade
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <style jsx>{`
                .pricing-container {
                    margin-top: 50px;

                }
                
                @media (max-width: 762px) {
                    .MuiGrid-container {
                        flex-direction: column !important;
                    }
                    .MuiGrid-item {
                        width: 100% !important;
                    }
                    .pricing-container {
                        margin-top: 100px;

                    }
                    .MuiCardContent-root {
                        padding: 8px;
                    }
                    .MuiTypography-h5 {
                        font-size: 1.2rem;
                    }
                    .MuiTypography-h6 {
                        font-size: 1rem;
                    }
                    .MuiButton-sizeLarge {
                        font-size: 0.875rem;
                    }
                }
            `}</style>   
        </div>
    );
}

export default Pricing;
