import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import './table.css';
import PropTypes from 'prop-types';
import Result from '../Reports/report';

const TableEditor = ({
  data,
  handleAddRow,
  handleDeleteRow,
  handleMenuOpen,
  handleMenuClose,
  handleAnalysisMethodSelect,
  handleVisualizeData,
  handleCleanData,
  generateReport,
  selectedRows,
  setSelectedRows,
  anchorEl,
  visualizeResponse,
  reportText,
  showReport
}) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      processData(data);
    }
  }, [data]);

  const processData = (data) => {
    const headerRow = data[0];
    const rowData = data.slice(1);

    const newColumns = headerRow.map((col, index) => ({
      field: `col${index}`,
      headerName: col || `Column ${index + 1}`,
      width: 150,
    }));

    const newRows = rowData.map((row, index) => ({
      id: index,
      ...row.reduce((acc, curr, i) => ({ ...acc, [`col${i}`]: curr }), {}),
    }));

    setColumns(newColumns);
    setRows(newRows);
  };

  const handleCellEditCommit = (params) => {
    const updatedRows = rows.map((row) => {
      if (row.id === params.id) {
        return { ...row, [params.field]: params.value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  return (
    <div className="container"  style={{marginTop:60}}>
      <div className="main-editor">
        {rows.length > 0 && (
          <div className="data-grid-container">
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(newSelection) => {
                setSelectedRows(newSelection);
              }}
              onCellEditCommit={handleCellEditCommit}
              components={{ Toolbar: GridToolbar }}
            />
          </div>
        )}
        {showReport && <Result visualizeResponse={visualizeResponse} reportText={reportText} />}
      </div>
    </div>
  );
};

TableEditor.propTypes = {
  data: PropTypes.array.isRequired,
  handleAddRow: PropTypes.func.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  handleMenuOpen: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  handleAnalysisMethodSelect: PropTypes.func.isRequired,
  handleVisualizeData: PropTypes.func.isRequired,
  handleCleanData: PropTypes.func.isRequired,
  generateReport: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  visualizeResponse: PropTypes.object,
  reportText: PropTypes.string,
  showReport: PropTypes.bool.isRequired,
};

export default TableEditor;
